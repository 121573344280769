import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentSessions } from 'actions/paymentSessions';
import Loader from 'common/Loader';
import CybersourceContainer from 'lib/cybersource';

function CardDetailsInputV2({ onValidate, getToken, onToken }) {
  const dispatch = useDispatch();
  const { paymentSessions } = useSelector((state) => ({
    paymentSessions: state?.paymentSessions,
  }));

  useEffect(() => {
    dispatch(getPaymentSessions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTokenHandler = (token) => {
    onToken(token);
  };

  const {
    isReady,
    inProgress,
    isSuccess,
    data: paymentSessionsData,
  } = paymentSessions;

  if (inProgress) return <Loader />;

  if (!isSuccess) return <>Failed try again</>;

  if (isReady) {
    return (
      <>
        <CybersourceContainer
          paymentSessionsData={paymentSessionsData}
          getToken={getToken}
          onToken={onTokenHandler}
          onValidate={onValidate}
        />
      </>
    );
  }

  return <></>;
}

export default CardDetailsInputV2;
