import { withFeatureFlags } from 'components/context/withFeatureFlags';
import { isFunction } from 'lodash';
import React, { useEffect, useState } from 'react';

function LoadScript({
  paymentSessionsData,
  onLoaded = () => {},
  getFeatureFlags,
}) {
  const [isMicroformScriptLoaded, setMicroformScriptLoaded] = useState(false);
  const [
    fingerprintScriptLoadedTimestamp,
    setFingerprintScriptLoadedTimestamp,
  ] = useState(false);

  useEffect(() => {
    isMicroformScriptLoaded &&
      fingerprintScriptLoadedTimestamp &&
      isFunction(onLoaded) &&
      onLoaded(fingerprintScriptLoadedTimestamp);
  }, [isMicroformScriptLoaded, fingerprintScriptLoadedTimestamp, onLoaded]);

  useEffect(() => {
    const { vendor_config: vendorConfig } =
      getFeatureFlags('PAYMENT_CONFIG') || {};
    const { fingerprintScript: fingerprintScriptUrl = '' } = vendorConfig || {};

    const { orgId, merchantId, sessionId, clientLibrary } =
      paymentSessionsData || {};

    const microformScript = document.createElement('script');
    microformScript.setAttribute('src', clientLibrary);
    microformScript.id = 'MicroformScript';
    microformScript.async = true;
    microformScript.onload = () => {
      setMicroformScriptLoaded(true);
    };

    const fingerprintScript = document.createElement('script');
    fingerprintScript.setAttribute(
      'src',
      `${fingerprintScriptUrl}?org_id=${encodeURIComponent(
        orgId,
      )}&session_id=${encodeURIComponent(`${merchantId}${sessionId}`)}`,
    );
    fingerprintScript.id = 'FingerprintScript';
    fingerprintScript.async = true;
    fingerprintScript.onload = () => {
      setFingerprintScriptLoadedTimestamp(Date.now());
    };

    document.head.append(microformScript);
    document.head.append(fingerprintScript);

    return () => {
      document.head.removeChild(microformScript);
      document.head.removeChild(fingerprintScript);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSessionsData]);

  return <></>;
}

export default withFeatureFlags(LoadScript);
