import React from 'react';
import { useState } from 'react';
import LoadScript from './components/LoadScript';
import Form from './components/Form';
import Alerts from 'components/ui/ui-kit/Alerts';
import { withFeatureFlags } from 'components/context/withFeatureFlags';
import { isEmpty } from 'lodash';

function CybersourceContainer({
  paymentSessionsData,
  onToken,
  getToken,
  onValidate,
  getFeatureFlags,
}) {
  const { vendor_config: { defaultErrorMessage, ctaErrorMessage } = {} } =
    getFeatureFlags('PAYMENT_CONFIG') || {};

  const initErrorState = { hasError: false, error: {} };
  const [formError, setFormError] = useState(initErrorState);
  const [isScriptsReady, setIsScriptReady] = useState(false);
  const [
    fingerprintScriptLoadedTimestamp,
    setFingerprintScriptLoadedTimestamp,
  ] = useState(false);

  const { context, sessionId } = paymentSessionsData || {};

  const scriptOnLoadHandler = (timestamp) => {
    setFingerprintScriptLoadedTimestamp(timestamp);
    setIsScriptReady(true);
  };

  const onTokenHandler = (token) => {
    onToken({
      sessionId,
      token,
    });
  };

  const onFormErrorHandler = (error) => {
    setFormError({
      hasError: true,
      error,
    });
  };

  const resetFormError = () => {
    setFormError(initErrorState);
  };

  const errorTitle = () => {
    return (
      (isEmpty(formError?.error?.message)
        ? defaultErrorMessage
        : formError?.error?.message) || ''
    );
  };

  return (
    <>
      <LoadScript
        paymentSessionsData={paymentSessionsData}
        onLoaded={scriptOnLoadHandler}
      />
      {isScriptsReady && (
        <Form
          context={context}
          onValidate={onValidate}
          fingerprintScriptLoadedTimestamp={fingerprintScriptLoadedTimestamp}
          onTokenHandler={onTokenHandler}
          onFormErrorHandler={onFormErrorHandler}
          getToken={getToken}
        />
      )}
      {formError?.hasError && (
        <Alerts
          className={'mt-32 text-left'}
          type='failure'
          title={errorTitle()}
          message={ctaErrorMessage || ''}
          onClose={resetFormError}
          open={true}
          showClose={true}
        />
      )}
    </>
  );
}

export default withFeatureFlags(CybersourceContainer);
